// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-series-run-1-matisse-jsx": () => import("./../../../src/pages/series/run-1-matisse.jsx" /* webpackChunkName: "component---src-pages-series-run-1-matisse-jsx" */),
  "component---src-pages-series-run-1-miro-jsx": () => import("./../../../src/pages/series/run-1-miro.jsx" /* webpackChunkName: "component---src-pages-series-run-1-miro-jsx" */),
  "component---src-pages-series-run-1-mondrian-jsx": () => import("./../../../src/pages/series/run-1-mondrian.jsx" /* webpackChunkName: "component---src-pages-series-run-1-mondrian-jsx" */),
  "component---src-pages-series-run-1-rothko-jsx": () => import("./../../../src/pages/series/run-1-rothko.jsx" /* webpackChunkName: "component---src-pages-series-run-1-rothko-jsx" */),
  "component---src-pages-series-run-1-van-gogh-jsx": () => import("./../../../src/pages/series/run-1-van-gogh.jsx" /* webpackChunkName: "component---src-pages-series-run-1-van-gogh-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-templates-print-detail-jsx": () => import("./../../../src/templates/PrintDetail.jsx" /* webpackChunkName: "component---src-templates-print-detail-jsx" */)
}

